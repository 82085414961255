
.divpinf582 {
    padding: 50px;
}

.cardbig {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
    background: rgba(70, 70, 70, 0.5) !important ;
    border: solid;
    border-color: rgba(255, 255, 255, 0.8) !important;
    border-width: 1px;
    border-radius: 7px;
    padding: 40px;
}

.idmfk {
    width: 350px;
    height: 350px;
    margin: auto;
    border-radius: 7px;
}

.dolf {
        padding-left: 30px !important;
        padding-right: 30px !important;
}

.god85 {
    color:rgba(255, 255, 255, 0.8);
    font-weight: 300;
    font-family: "Righteous",Sans-serif;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 28px;
}

.bofimg {
    width: 300px;
}

.box1fp {
    padding: 30px;
    margin-left: 40px;
    margin-right: 40px;
    background-color: rgba(244, 180, 147, 0.05);
    border-radius: 5px;
}

.nufod {
    margin-top: 100px;
    margin-bottom: 50px;
    justify-content: center;
}

.fodliv {
    text-align: center;
}


@media screen and (min-width: 600px) and (max-width: 960px) {
    .nufod {
        display: inline-block !important;
        margin-top: 70px;
    }

    .box1fp {
        margin-bottom: 30px;
        padding: 20px;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
    }

    .bofimg {
        width: 220px;
    }
}

@media screen and (max-width: 600px) { 
    .nufod {
        display: inline-block !important;
        margin-top: 70px;
    }

    .box1fp {
        margin-bottom: 30px;
        padding: 20px;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
    }

    .bofimg {
        width: 220px;
    }
}