.bosimf4:hover {
    -webkit-transform:scale(1.25);
    -moz-transform:scale(1.25);
    -ms-transform:scale(1.25);
    -o-transform:scale(1.25);
    transform:scale(1.25);
    transition: 0.5s;
}

.bosimf4 {
    -webkit-transform:scale(1);
    -moz-transform:scale(1);
    -ms-transform:scale(1);
    -o-transform:scale(1);
    transform:scale(1);
    transition: 0.5s;
}

.btnfhome, .btnfhome1 {
    margin-right: 30px;
    width: 200px;
    font-family: "Righteous";
}

/* --------------------------------------------------------------------------------------------------------------- */

.bod5s, .bod5s2 {
    width: 50%;
    text-align: center;
}

.textprinf5 {
    font-size: 65px;
    color: rgba(255, 255, 255, 0.7) !important;
}

.jcjjcm {
    padding-top: 100px;
    padding-bottom: 30px;
}

.bod5s2ww {
    display: none !important;
}

.olf85esss {
    /* background: green; */
    text-align: center;
    font-size: 35px;
    letter-spacing: 2px;
    color: rgba(255, 255, 255, 0.7) !important;
    /* margin-bottom: 40px; */
    display: block;
    font-family: "Righteous";
    width: fit-content;
    margin: auto;
    padding: 10px;
}

.olf85esss22 {
    font-size: 17px;
    display: flex;
    justify-content: center;
} 

.olf85esss21 {
    /* background: blue; */
    font-size: 30px;
    display: flex;
    justify-content: center;
}

.foldss {
    margin-top: 40px;
}

.oledas {
    margin: 0 5px 0 5px;
    width: 70px;
    /* background: green; */
}


/* .olf85esss1 {
    font-size: 40px;
}

.olf85esss2 {
    font-size: 50px;
} */

@media screen and (min-width: 600px) and (max-width: 960px) {
    .textprinf5 {
        font-size: 37px;
    }

    .bod5s {
        width: auto;
    }

    .jcjjcm {
        padding-top:50px;
        padding-bottom:0px;
    }

    .clofp22 {
        margin-bottom: 100px;
    }

    .bod5s2 {
        display: none !important;
    }

    .btnfhome {
        margin-right: 10px;
        width: 140px;
        font-family: "Righteous";
    }

    .btnfhome1 {
        width: 140px;
        font-family: "Righteous";
    }

    .foldss {
        padding-left: 15px;
    }

    .bod5s2ww {
        display: block !important;
        padding-bottom: 30px;
        padding-top: 30px;
    }
  }

  @media screen and (max-width: 600px) {
    .textprinf5 {
        font-size: 37px;
    }

    .bod5s {
        width: auto;
    }

    .jcjjcm {
        padding-top:50px;
        padding-bottom:0px;
    }

    .clofp22 {
        margin-bottom: 100px;
    }

    .bod5s2 {
        display: none !important;
    }

    .btnfhome {
        margin-right: 10px;
        width: 140px;
        font-family: "Righteous";
    }

    .btnfhome1 {
        width: 140px;
        font-family: "Righteous";
    }

    .foldss {
        padding-left: 15px;
    }

    .bod5s2ww {
        display: block !important;
        padding-bottom: 30px;
        padding-top: 30px;
    }
  } 