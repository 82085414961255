.btn-primary {
    background-color: transparent !important;
    border-color: rgba(255, 255, 255, 0.7) !important;    
    color: rgba(255, 255, 255, 0.7) !important;
    font-size: 15px !important;
    font-family:"Righteous";

}

.btn-primary:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-color: rgba(255, 255, 255, 0.7) !important;
    color: rgba(255, 255, 255, 0.7) !important;
}

.btn-primary:active {
    background-color: transparent !important;
    border-color: rgba(255, 255, 255, 0.7) !important;
    color: rgba(255, 255, 255, 0.7) !important;
}

.btn-primary:visited {
    background-color: transparent !important;
    border-color: rgba(255, 255, 255, 0.7) !important;
    color: rgba(255, 255, 255, 0.7) !important;
}


/* ---------------------------------------------------------------------------------------------------------- */


.web3modal-modal-card{
    width: 350px!important;
    min-width: unset!important;
    max-width: unset!important;
    /* border-color: #f3976a!important ; */
}

.web3modal-provider-container{
    /* flex-direction: row!important; */
    padding: 5px!important;
}

.web3modal-provider-description{
    display:none;
}

/* ---------------------------------------------------------------------------------------------------------- */
.flepo {
    height: 60px;
}

.olr85dd {
    width: 140px !important;
}

.bofinav {
    font-size: 30px;
    margin: 0 30px;
    display: flex;
    color: rgba(255, 255, 255, 0.7) !important;
}

.bofinav:hover {
    color: rgba(255, 255, 255, 0.7) !important;

}

.fleido5 {
    width: 15%;
    padding: 12px 0;
    justify-content: right;
    /* background: blue; */
}

.owdvz {
    width: 25%;
    /* padding: 1px; */
}

.fled52 {
   width: 15%;
   /* background: green; */
}

.fjdkv {
    margin: 5px 10px;
    /* background-color: aqua; */

}

.butnf {
    display: none;
}

@media screen and (min-width: 600px) and (max-width: 960px) {
    .bofinav {
        font-size: 20px;
        margin:0;
    }

    .flexbarras, .fled52 {
        display: none !important;
    }

    .fleido5 {
        width: fit-content;
    }

    .owdvz {
        width: fit-content;
        padding: 0;
    }

    .fjdkv {
        margin:  0 5px;
    }

    .flepo {
        justify-content: space-around;
    }

    .butnf {
        margin-left: 10px;
        display: inline-block;
    }
}

@media screen and (max-width: 600px) {
    .bofinav {
        font-size: 20px;
        margin:0;
    }

    .flexbarras, .fled52 {
        display: none !important;
    }

    .fleido5 {
        width: fit-content;
    }

    .owdvz {
        width: fit-content;
        padding: 0;
    }

    .fjdkv {
        margin:  0 5px;
    }

    .flepo {
        justify-content: space-around;
    }

    .butnf {
        margin-left: 10px;
        display: inline-block;
    }
}