.imf4wq {
    width: 300px;
    height: 300px;
    margin: auto;
    border-radius: 7px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.sdivlsd6{
    width: 33%;
    margin: auto;
}

.sdas333 {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    background: rgba(70, 70, 70, 0.5) !important ;
    border: solid;
    border-color: rgba(255, 255, 255, 0.7) !important;
    border-width: 1px;
    border-radius: 7px;
}

.divsa2 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

.sad85d {
    color:rgba(255, 255, 255, 0.7);
    font-weight: 400;
    font-family: "Righteous",Sans-serif; 
    padding-bottom: 10px;
    font-size: 40px;
}

.sad85dss {
    color:rgba(255, 255, 255, 0.7);
    font-weight: 400;
    font-family: "Righteous",Sans-serif; 
    padding-bottom: 20px;
    font-size: 40px;
}

.sad85dssqq {
    color:rgba(255, 255, 255, 0.7);
    font-weight: 400;
    font-family: "Righteous",Sans-serif; 
    padding-bottom: 20px;
    font-size: 35px;
}

.crd85 {
    margin: auto;
    text-align: center;
    padding-top: 30px;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 50px;
}

.h2cards {
    color:rgba(255, 255, 255, 0.7);
    font-weight: 300;
    font-family: "Righteous",Sans-serif;
    margin-bottom: 10px;
}

.p1fwq {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);
    padding-top: 20px;
}

.p1fq {
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);
}

.cact5 {
    margin-top: 10px;
    margin-bottom: 20px;
}

.dksa {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 30px !important;
}

.h2fx {
    text-align: center;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.7);
    font-family: "Righteous",Sans-serif;
    padding-bottom: 15px;
}

.p12fq {
    font-size: 15px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    font-family: "Righteous",Sans-serif;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 300;
}

.p12fqq {
    font-size: 17   px !important;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    font-family: "Righteous",Sans-serif;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 500;
}

.h1fol {
    font-size: 50px;
    margin-bottom: 30px;
}

.h1fol2 {
    font-size: 20px;
}

.divider {
    width: 70%;
    margin-top: 20px !important;
    margin: auto;
    border-top: 3px solid rgba(255, 255, 255, 0.7);
}

.fidkf {
    font-size: 35px;
    margin-bottom: 30px;
}

.eds {
    font-size: 25px;
    margin-top: 25px;
}

.forltjd5 {
    /* width:55%; */
    padding: 50px 70px 50px 70px; 
    /* margin-left:70px; */
}

.fikfg {
    /* margin: 0 70px 50px 70px; */
    width: 50%;
    /* padding: 40px; */
}

.orlgid5q {
    width: 350px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.plolff {
    width: 50%;
    /* background-color: aqua; */
    /* text-align: center;
    margin: auto; */
    /* background-color: aqua;   */
    margin: auto;
    

    
}

.ppllf {
    margin: 0 0 70px 30px;
    padding: 40px;
}

.olrg522 {
    width: 50%;
    /* background-color: aqua; */
}

.olrg522w {
    width: 60%;
    margin-top: 100px;
}

.olfww {
    border-radius: 5px !important;
    padding: 0 50px 0 50px;
    text-align: center;
}

.savvvc {
    padding-right: 150px;
    padding-left: 150px;
     
}

.savvvcs {
    padding-right: 120px;
    width: 50%;
    /* padding-top: 40px; */
    margin: auto;
    /* background-color: blanchedalmond; */
    
}

.askca {
    /* background: blue; */
    text-align: center;
    justify-content: center;
    margin: auto;
    /* padding: 0 0 50px 250px; */
    padding: 0 0 50px 0;
    
}

 @media screen and (min-width: 600px) and (max-width: 960px) {
    .savvvcs {
        padding-right: 50px;
        padding-left: 50px;
        padding-top: 70px;
        width: 100%;    
    }

    .askca {
       display: inline-block !important;
       padding: 0 0 50px 0;

    }
   
    .orlgid5q {
       width: 200px;
    }
   
    .ppllf {
       margin: 0;
       padding: 25px;
    }
   
    .olrg522 {
       width: 100%;
       padding: 0;
       margin-bottom: 50px;
       justify-content: center;
    }
   
    .forltjd5 {
       width: auto;
       padding: 15px;
       margin: 50px 10px 50px 10px;
   }
   
   .olfww {
       padding: 20px;
    }
   
    .fikfg {
       margin: 10px 10px 50px 10px;
       padding: 0px;
    }
   
    /* .kffs {
       margin: 15px;
       border-radius: 5px;
       padding: 0 100px 0 20px;  
    } */
   
    /* .igkgdaa {
        padding: 15px 5px 15px 5px;
    } */
   
    .h2fx {
       font-size: 40px;
    }
    .plolff {
        width: 100%;    
    }
}

@media screen and (max-width: 600px) {
    .plolff {
        width: 100%;    
    }

    .olrg522w {
        width: 100%;
        /* margin-top: 100px; */
    }

    .savvvcs {
        padding-right: 20px;
        padding-left: 20px;
        margin-top: 40px;
        margin-bottom: 50px;
        width: 100%;    

    }
    
 .askca {
    display: inline-block !important;
    padding: 0 0 50px 0;

 }

 .orlgid5q {
    width: 200px;
 }

.ppllf {
    margin: 0;
   padding: 25px;
}

.olrg522 {
    width: auto;
    padding: 0;
    margin-bottom: 50px;
    justify-content: center;
}

.forltjd5 {
    width: auto;
    padding: 15px;
    margin: 50px 10px 20px 10px;
}

.olfww {
    padding: 0 20px 20px 20px;
 }

 .fikfg {
    margin: 10px 10px 50px 10px;
    padding: 0px; 
 }

 .kffs {
    margin: 15px;
    border-radius: 5px;
    padding: 0;
 }

 .igkgdaa {
    padding: 15px 5px 15px 5px;
 }

 .h2fx {
    font-size: 40px;
 }

 .h1fol {
    font-size: 40px;
    margin-bottom: 30px;
}

.h1fol2 {
    font-size: 15px;
}
}