.idmfkqq {
    width: 350px;
    border-radius: 10px;
}

button.xmint-btn {
    background-color: transparent !important;
    border-color: rgba(255, 255, 255, 0.7) !important;    
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px !important;
    border-width: 1px !important;
    border-radius: 5px;
    border: solid;
    font-family:"Righteous";
    font-weight: 100;
    margin: auto;
    
}

.eedqa {
    margin-bottom: 15px;
}

.sadvvv {
    display: flex;
    justify-content: center;
}

.dddw {
    background: aqua !important;
    place-content: center;
    place-items: center;
}

.fleifkso {
    text-align: center;
    /* background: #f3976a; */
}

.minf7 {
    color: white;
    padding: 70px;
}

.mintcontod {
    text-align: center;
    margin: auto;
    flex-wrap: wrap;
    background: rgba(70, 70, 70, 0.5) !important ;
    border: solid;
    border-color: #f3976a !important;
    border-width: 1px;
    border-radius: 30px;
    padding: 40px;
}

.div1fo {
    padding-bottom: 50px;
    width: 70%;
    margin: auto;
}

.divp8 {
    display: flex;
}

.folds {
    width: 50%;
}

.djfuvo {
    width: 50%;
}

.inpuif {
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    border-width: 1px;
    background: transparent;
    color: rgba(255, 255, 255, 0.7);
    width: 40%;
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.olfdww {
    margin-bottom: 20px;
    font-size: 50px;
}

.sdkfs {
    margin-bottom: 30px;
}

.idksp {
 width: 300px;
}

.h6d5 {
    margin-bottom: 10px;
}

.h6d51 {
    margin-bottom: 10px;
}

.bolsaa{
    /* width: 50%; */
    /* margin-left: 10%; */
    margin: auto;
}

.folssgd {
    padding: 0 70px 0 70px;
    justify-content: space-around;
    text-align: center;
}

.ddf52s {
    padding:20px;
    width:50%;
}

.filg85 {
    margin: 50px 150px 0 150px;
    padding: 40px;
}

@media screen and (min-width: 600px) and (max-width: 960px) {
    .fleifkso {
        display: inline-block !important;
        width: 100%;
        padding: 0 80px 0 80px;
    }

    .idksp {
        width: 300px;
    }

    .bolsaa{
        width: fit-content;
        padding-bottom: 40px;
        
    }

    .ddf52s {
        padding:20px;
        width: auto;

    }

    .folssgd {
        padding: 0;
    }

    .sdcsacas {
        padding-left: 30px;
        padding-right: 30px;
    }

    .h6d51 {
        font-size: 18px;
    }

    .filg85 {
        margin: 50px 10px 50px 10px;
        padding: 30px;
    }
}


@media screen and (max-width: 600px) { 
    .fleifkso {
        display: inline-block !important;
    }

    .idksp {
        width: 200px;
    }

    .bolsaa{
        width: fit-content;
        padding-bottom: 40px;
    }

    .ddf52s {
        padding:20px;
        width: auto;
    }

    .folssgd {
        padding: 0;
    }

    .sdcsacas {
        padding-left: 30px;
        padding-right: 30px;
    }

    .h6d51 {
        font-size: 18px;
    }

    .filg85 {
        margin: 50px 10px 50px 10px;
        padding: 30px;
    }
}