@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');


.App {
  text-align: center;
  /* font-family: "Montserrat"; */
  font-family: "Righteous",Sans-serif;
  color: white;
}

* {
  box-sizing: border-box;
}

body {
  /* background-color: #212121 !important; */

/* background: linear-gradient(170deg, rgb(249, 131, 72) 17%, rgba(66,23,131,1) 90%) !important;  */
/* Old browsers */
/* background: -webkit-linear-gradient(180deg, rgba(249, 131, 72) 17%, rgba(66,23,131,1) 90%) !important; */
 /* Chrome10+,Safari5.1+ */


background: radial-gradient(circle, rgba(66,23,131,1) 15%, rgba(3,1,22,1) 100%) !important;

}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.moving-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/background/Untitled_Artwork.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 90% 90%;
}

.bg-progrend{
  /* background-color: #f3976a!important; */
    background-color: rgba(141, 47, 145, 0.8)!important;
}

.procid85{
  font-family: "Righteous";
 
}



