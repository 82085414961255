.span52 {
    margin-left: 100px;
    margin-right: 100px;
}

.fikf {
    padding: 20px;
    margin: 50px 220px 50px 220px; 
}

@media screen and (min-width: 600px) and (max-width: 960px) {
    .span52 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .fikf {
        margin: 50px; 
    }
}

@media screen and (max-width: 600px) {
    .span52 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .fikf {
        margin: 50px; 
    }
}